html {
  height: 100%;
  background-color: #eee;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #444;
  font-size: 12px;
  line-height: 18px;
}

.fontDIN{
  font-family: 'Oswald', sans-serif;
}

/* felix component start */
.editor-content {
  min-height: auto !important;
}

.yomi-form {
  max-height: 282px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ku-form{
  max-height: 282px;
  overflow-y: auto;
  overflow-x: hidden;
}

.debug-panel{
  max-height: 282px;
  overflow-y: auto;
  overflow-x: hidden;
}

.editor-navi {
  display: none;
}

.editor-navi-info{
  display: none;
}

.display-text {
  padding: 5px 50px !important;
  background-color: #EEF2F9 !important;
  border-radius: 0 !important;
  text-align: center !important;
  max-height: 160px !important;
  overflow-y: auto !important;
}

.mode-btn {
  margin-top: 30px !important;
  height: 44px !important;
  border: 1px solid #aaa !important;
  border-radius: 2px !important;
  box-sizing: border-box !important;
  width: 10px !important;
}

.TextGroup .yomi {
  color: #38496D;
  margin-bottom: 2px;
}

.TextGroup .hirabun {
  color: #38496D !important;
  height: 35px !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 28px !important;
  line-height: 33px !important;
  border-bottom: 2px solid #38496D !important;
}

.sentence-label {
  margin-left: 50px;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 23px !important;
  color: #444444 !important;
}

.yomi-edit > .label {
  width: 100px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #444444 !important;
}

.yomi-edit > .dic {
  width: 100px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #444444 !important;
}

.yomi-edit > .edit {
  background-color: #eee !important;
  border: 1px solid #eee !important;
  border-radius: 12px !important;
  height: 24px !important;
  padding: 5px 17px !important;
}

.yomi-edit .add-kuten {
  margin-left: 8px;
}

.yomi-edit .add-kuten button {
  width: 80px !important;
  height: 24px !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #fff !important;
  background: #6781B8 !important;
  border-radius: 12px !important;
  border: none !important;
}

.editor-duration {
  background: #EEF2F9 !important;
}

.duration-yomi-edit {
  display: flex;
  justify-content: space-between;
  width: 110px;
}

.duration-yomi-edit label {
  width: 48px !important;
  height: 30px !important;
  border-radius: 16px !important;
  font-size: 14px !important;
  line-height: 16px !important;
  padding: 5px 10px !important;
}

.duration-yomi-edit input[type="radio"]:checked + label {
  background: #6781B8 !important;
  border: none !important;
}

.duration-edit .remove-pause button{
  border: none !important;
  border-radius: 5px !important;
  background-color: #6781B8 !important;
  color: #fff !important;
}

.setting {
  text-align: center;
  margin-bottom: 30px !important;
}

.setting-inner {
  margin: 0 auto;
  width: 1024px !important;
}

.setting-tab {
  border-bottom: none !important;
  margin-bottom: 16px !important;
}

.setting .react-tabs__tab {
  color: #38496D !important;
  width: 120px !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.setting .react-tabs__tab--selected {
  color: #fff !important;
  border: none !important;
  background-color: #38496D !important;
  border-radius: 8px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
}

.textWhite {
  color: #fff !important;
}

/* #ku-edit-info {
  width: 98% !important;
  border: none !important;
  background-color: #F9F9F9 !important;
  border-radius: 4px !important;
} */

#ku-edit-info > span{
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #444 !important;
}

#accent-merge-button {
  width: 220px !important;
  height: 32px !important;
  border-radius: 16px !important;
  border: none !important;
  background-color: #6781B8 !important;
}

#accent-merge-button {
  width: 220px !important;
  height: 32px !important;
  border-radius: 16px !important;
  border: none !important;
  background-color: #6781B8 !important;
  color: #fff !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

#accent-merge-button:disabled {
  background-color: #eee !important;
  color: #aaa !important;
}

.ku-edit-word {
  border: none !important;
  background-color: #eee !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #444 !important;
  box-shadow: none !important;
}

.ku-edit-word-selected {
  background-color: #6781B8 !important;
  color: #fff !important;
}

.ku-edit-phrase {
  border: 1px solid #EEEEEE !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
}

.ku-edit-filler{
  border: none !important;
  background-color: white !important;
}

.ku-edit-filler .ku-edit-word {
  border: none !important;
  background-color: #EEF2F9 !important;
  width: 40px !important;
  display: flex;
  justify-content: center;
}

table.debug-morph-data {
  width: 100% !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #444444 !important;
  text-align: center;
}

table.debug-morph-data tr:nth-child(1) th:nth-child(1) {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 21px !important;
  text-align: center !important;
  color: #444444 !important;
}

table.debug-morph-data th.accent {
  background-color: #EEF2F9 !important;
}

table.debug-morph-data tr td:nth-of-type(1), table.debug-morph-data tr td:nth-of-type(10), table.debug-morph-data tr td:nth-of-type(11), table.debug-morph-data tr td:nth-of-type(14) {
  background-color: #F9F9F9 !important;
}

table.debug-morph-data th.accent:nth-child(2) {
  font-size: 14px !important;
}

.rawdata {
  width: 100% !important;
  background-color: #444 !important;
  border-radius: 4px !important;
}

.rawdata code {
  font-size: 12px !important;
}

.yomi-edit .add-kuten button:hover:after {
  background-color: rgba(0, 0, 0, 0) !important;
}

/* felix component end */

::-webkit-scrollbar{
  width: 16px;
  height: 16px;
}
::-webkit-scrollbar-track{
  background: #eee;
  border: 5px solid #fff;
}
::-webkit-scrollbar-thumb{
  border: 3px solid #fff;
  background: #38496D;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

#root {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  background-color: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span {
  font-family: inherit;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
}

.f10{
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
}

.f12 {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.f14 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.f16{
  font-size: 16px;
  line-height: 24px;
}

.f18{
  font-size: 18px;
  line-height: 20px;
}

.f20{
  font-size: 20px;
  line-height: 30px;
}

.f24{
  font-size: 24px;
  line-height: 36px;
}

.f28 {
  font-size: 28px;
  line-height: 42px;
}

.mr10 {
  margin-right: 10px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml6 {
  margin-left: 6px !important;
}

.ml8 {
  margin-left: 8px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml12 {
  margin-left: 12px !important;
}

.ml16 {
  margin-left: 16px !important;
}

input {
  font-weight: bold;
}

.primaryText {
  color: #38496D
}
.subText {
  color: #6781B8
}

.grayText {
  color: #aaaaaa
}

.requestGrayText {
  font-size: 15px;
  line-height: 22px;
  color: #aaaaaa
}

.verticalCenter {
  display: flex;
  align-items: center;
}

.alignCenter {
  display: flex;
  justify-content: center;
}

.formLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formControl{
  display: flex;
  align-items: center;
}

.labelText {
  margin-left: 14px;
}
.cameraLabelText {
  font-size: 16px;
  line-height: 24px;
}

.vguardNameText {
  font-size: 22px;
  line-height: 33px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.formTextFiled{
  padding: 10px 8px;
}

.tooltip{
  max-width: 300px;
  font-size: 16px;
}

.Mui-error {
  word-break: keep-all;
}

.wordBreakAll {
  word-break: break-all;
}

.titleText {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
}

.popupTitleText {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 28px;
}

.popupContentText {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
}

.deletePopupContentText {
  width: 540px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
}

.wordKeepAll {
  display: inline-block;
  text-align: right;
  vertical-align: top;
  max-width: calc(100% - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wordKeepAllClose {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  width: 20px;
}

.buttonText {
  font-family: inherit;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF;
}

.vguardSettingTitle{
  font-family: inherit;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
}

.fieldText{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #444444;
}

.approvalRequestText{
  font-size: 18px;
  line-height: 21px;

  color: #444444;
}

.approvalRequestWaitText{
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #444444;
}

.accountTitleText{
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;

  color: #444444;
}

.contentEditLabel {
  min-width: 100px;
  max-width: 100px;
}
.contentEditLabel80 {
  min-width: 80px;
  max-width: 80px;
}
.contentEditLabel120 {
  min-width: 120px;
  max-width: 120px;
}

.loginLabel {
  width: 119px;
}

.fitText {
  min-inline-size: fit-content;
}

.timezoneBox {
  max-width: 16px;
  max-height: 16px;
  padding: 3px;
  margin: 1px;
  background-color: #38496D;
  border-radius: 2px;
  font-family: inherit;
  font-size: 10px;

  color: #fff;
}

.timezoneBoxDisabled {
  max-width: 16px;
  max-height: 16px;
  padding: 3px;
  margin: 1px;
  background-color: #AAAAAA;
  border-radius: 2px;
  font-family: inherit;
  font-size: 10px;

  color: #fff;
}

.greetingForm {
  width: 352px;
  height: 24px;
  align-self: flex-end;
}

.timeoutTypeField {
  width: 228px;
  height: 24px;
}

.timeoutSecField {
  width: 70px;
  height: 24px;
  border-radius: 4px;
}

.headCellText{
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  white-space: nowrap;
}

.keywordDeleteText{
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #38496D;
}

.synonymText{
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.keywordNumber{
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  text-align: right;
}

.newLineHeadCell{
  text-align: center;
  display: inline-block;
}

.vguardSuffixText{
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  margin-left: 6px;
}

.f16L19{
  font-size: 16px;
  line-height: 19px;
}

.f12L14{
  font-size: 12px;
  line-height: 14px;
}

.f18L27{
  font-size: 18px;
  line-height: 27px;
}

.mt5mb10{
  margin-top: -5px;
  margin-bottom: 10px;
}

.multilineMask {
  width: 269px;
  height: 44px;
  overflow: hidden;
  position: relative;
  background-color: transparent;
}
.multilineMask::after {
  width: 12px;
  text-align: center;
  content: "...";
  position: absolute;
  top: 22px;
  left: 206px;
  background-color: #eee;
}

.multilineMask.quoteRed::after {
  color: #F8431C;
  font-weight: bold;
}

.thousandSeparator {
  width: 64px;
  height: 24px;
  font-weight: inherit;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  background-color: #eee;
  border-color: transparent;
  border-radius: 4px;
}

.thousandSeparatorInput {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-weight: inherit;
  font-style: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  background-color: transparent;
  padding: 0 4px;
  border: 0;
  outline: none;
}

.thousandSeparatorInput:disabled {
  color: rgba(0, 0, 0, 0.38);
}

.tableCommandOrder {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}